import React, { useCallback } from 'react'
import Container from '../../../../components/Container'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import DataTable from 'components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { API_NAME_TRANSLATE_ENUM, MASKS } from 'common/constants'
import api from 'services/api'
import moment from 'moment'
import { useToast } from 'hooks/toast'
import { useUpdateDataTable } from 'hooks/dataTable'
const CURRENT_DATE_ISO = moment().format('YYYY-MM-DD')
const List: React.FC = () => {
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()
  const handleExport = useCallback(
    async (item: any) => {
      // if (item.status !== 'finished') {
      //   addToast({
      //     title: 'Exportação indisponível',
      //     description:
      //       'Exportação indisponível porque a consulta ainda não foi finalizada',
      //     type: 'info'
      //   })
      //   return
      // }
      const currentDateInTimestamp = moment().valueOf()
      try {
        const response = await api.get(
          `/commercial/queryBatchs/export/${item.id}`
        )
        const blob = new Blob(['\ufeff' + response.data], {
          type: 'text/csv;charset=utf-8"'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = `${currentDateInTimestamp}-consulta-lotes-${CURRENT_DATE_ISO}.csv`
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      } catch (error) {
        console.log(error)
      }
      updateDataTable()
    },
    [updateDataTable]
  )

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: 'id' }}
        notHasChildren
        headers={headers}
        hasSearchInput
        customHeaders={[
          {
            name: 'Tipo',
            field: 'api_name',
            sortable: true,
            element: item => {
              return <p>{API_NAME_TRANSLATE_ENUM[item.api_name]}</p>
            }
          },
          {
            name: 'Exportado em',
            field: 'exported_at',
            sortable: false,
            element: item => {
              return (
                <p>
                  {item.exported_at
                    ? moment(item.exported_at, MASKS.DATE.LOCALE).format(
                        MASKS.DATE.LOCALE
                      )
                    : ''}
                </p>
              )
            }
          }
        ]}
        actions={[
          {
            name: 'exportar',
            title: 'Exportar',
            onClick: item => handleExport(item),
            element: item => {
              return <span className="fa fa-file-excel"></span>
            }
          }
        ]}
      />
    </Container>
  )
}
export default List
