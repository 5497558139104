import React, { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { Datalist } from 'components/Datalist'
import { Loading } from 'components/Loading'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type QueryBatchData = {
  agreement_group_id: string
  api_name: string
  quantity_requested: number
  status: string
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: QueryBatchData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormQueryBatch = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [agreementGroups, setAgreementGroups] = useState<
    {
      name: string
      value: number
      id: number
    }[]
  >([
    {
      name: '',
      value: 0,
      id: 0
    }
  ])
  const [agreementGroupValue, setAgreementGroupValue] = useState({
    name: '',
    value: 0,
    id: 0
  })
  const [defaultValues, setDefaultValues] = useState<QueryBatchData>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getAgreementGroups = useCallback(async () => {
    setIsLoading(true)
    const response = await api.get('/operational/agreementGroups')
    const agreementGroupsSelect = response.data?.map(
      (agreementGroup: { id: number; name: string }) => ({
        value: agreementGroup.id,
        name: agreementGroup.name,
        id: agreementGroup.id
      })
    )
    setAgreementGroups(agreementGroupsSelect)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    getAgreementGroups()
  }, [getAgreementGroups])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: QueryBatchData) => {
    const id = initialValues?.idUpdate
    data.agreement_group_id = agreementGroupValue?.id?.toString()
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          const dataCreate = {
            ...data
          }
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          const dataCreate = {
            ...data
          }
          try {
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <>
      <Loading isActive={isLoading} />
      <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
        <div className="form">
          <div className="row mb-5">
            <Datalist
              className="col-6"
              data={agreementGroups}
              label="Grupo de Convênio"
              setValue={setAgreementGroupValue}
              value={agreementGroupValue}
            />
            <Select
              name="api_name"
              label="Tipo"
              className="col-3"
              options={[
                {
                  name: 'Credcesta',
                  value: 'credcesta'
                },
                {
                  name: 'Santander correntista',
                  value: 'santander_correntista'
                },
                {
                  name: 'Santander margem',
                  value: 'santander_margem'
                },
                {
                  name: 'QI',
                  value: 'qi'
                }
              ].sort((currentItem, nextItem) =>
                currentItem.name.localeCompare(nextItem.name)
              )}
              blank
              rules={{ required: true }}
            />
            <Input
              className="col-3"
              name="quantity_requested"
              label="Quantidade"
              rules={{ required: true }}
            />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </Form>
    </>
  )
}
